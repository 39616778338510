// src/slices/tooltipSlice.js
import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";

// Define the async thunk for fetching tooltip data
export const fetchTooltips = createAsyncThunk(
  "tooltip/fetchTooltips",
  async (_, {rejectWithValue}) => {
    try {
      const response = await axios.get(
        `${process.env.NEXT_PUBLIC_UTILITY_URL}/display-information/categories/pecunia-admin`
      );
      const data = response.data;

      // Store data in sessionStorage
      sessionStorage.setItem("tooltip-data", JSON.stringify(data));

      return data;
    } catch (err: any) {
      console.error("Error fetching tooltip data:", err);
      return rejectWithValue(err.response.data);
    }
  }
);

export interface Tooltip {
  id: number;
  language_code: string;
  slug: string;
  text: string;
  variables: string | null;
  category: string;
  created_on: string;
  created_by: string | null;
  modified_on: string;
  modified_by: string | null;
  deleted_flag: number;
  deleted_on: string | null;
  deleted_by: string | null;
}

export interface TooltipDataProps {
  data: Tooltip[];
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
}

// Define the initial state
const initialState: TooltipDataProps = {
  data: [],
  status: "idle",
  error: null,
};

// Create the slice
const tooltipSlice = createSlice({
  name: "tooltip",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTooltips.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchTooltips.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload.data; // Assuming response.data contains the array
        sessionStorage.setItem(
          "tooltip-data",
          JSON.stringify(action.payload.data)
        );
      })
      .addCase(fetchTooltips.rejected, (state, action) => {
        state.status = "failed";
        state.error = (action.error.message as string) || "";
      });
  },
});

export default tooltipSlice.reducer;
