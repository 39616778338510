import {createSlice, createAsyncThunk, PayloadAction} from "@reduxjs/toolkit";
import axios from "axios";

interface AllIntegrationsState {
  integrations: {id: number; attributes: any}[];
  status: "idle" | "loading" | "succeeded" | "failed";
  url?: string;
  error: string | null;
  category: string | null;
  searchTerm: string;
}

const initialState: AllIntegrationsState = {
  integrations: [],
  status: "idle",
  error: null,
  category: "All",
  searchTerm: "",
};

const clearURLParams = () => {
  const newURL = `${window.location.pathname}`;
  window.history.pushState({}, "", newURL);
};

const updateURLParams = (state: AllIntegrationsState) => {
  const params = new URLSearchParams();

  const mappings: Partial<Record<keyof AllIntegrationsState, string>> = {
    category: state.category ?? "",
  };

  Object.entries(mappings).forEach(([key, value]) => {
    if (value) params.set(key, value.toString());
  });

  const newURL = `${
    state.url ?? window.location.pathname
  }?${params.toString()}`;
  window.history.pushState({}, "", newURL);
};

const loadFromURLParams = (state: AllIntegrationsState) => {
  const params = new URLSearchParams(window.location.search);
  const mappings: {[key in keyof AllIntegrationsState]?: string} = {
    category: "category",
  };

  Object.entries(mappings).forEach(([stateKey, paramKey]) => {
    const value = params.get(paramKey);
    const key = stateKey as keyof AllIntegrationsState;

    if (value) {
      if (Array.isArray(state[key])) {
        const arrayValue = value
          .split(",")
          .map((val: string) => decodeURIComponent(val));
        (state[key] as any) = arrayValue;
      } else {
        (state[key] as any) = decodeURIComponent(value);
      }
    }
  });
};

export const fetchIntegrations = createAsyncThunk<
  any,
  void,
  {rejectValue: {error: string}}
>("integrations/fetchIntegrations", async (_, thunkAPI) => {
  const storedData = sessionStorage.getItem("integrations");
  if (storedData) {
    return JSON.parse(storedData);
  } else {
    try {
      const response = await axios.get(
        `${process.env.NEXT_PUBLIC_STRAPI_BASE_URL}/lendsqr-integration-tools?populate=*&pagination[page]=1&pagination[pageSize]=1000`,
        {
          headers: {
            Authorization: `Bearer ${process.env.NEXT_PUBLIC_STRAPI_API_KEY}`,
          },
        }
      );
      sessionStorage.setItem(
        "integrations",
        JSON.stringify(response.data.data)
      );
      return response.data.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({error: error.message});
    }
  }
});

const integrationsSlice = createSlice({
  name: "integrations",
  initialState,
  reducers: {
    setFilter(state, action: PayloadAction<Partial<AllIntegrationsState>>) {
      Object.assign(state, action.payload);
      updateURLParams(state);
    },
    clearFilters: () => {
      clearURLParams();
      return initialState; // Reset filter state to initial state
    },
    loadFilter(state) {
      loadFromURLParams(state);
    },
    searchIntegrations: (state, action) => {
      state.searchTerm = action.payload;

      const storedData = sessionStorage.getItem("integrations");

      if (storedData) {
        const providerData = JSON.parse(storedData);

        if (action.payload.length === 0) {
          state.integrations = providerData;
        } else {
          const filterDataByName = providerData.filter((item: any) =>
            item.attributes.integration
              .toLowerCase()
              .includes(action.payload.toLowerCase())
          );

          state.integrations = filterDataByName;
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchIntegrations.pending, (state) => {
        state.status = "loading";
      })
      // TODO - update generic type
      .addCase(
        fetchIntegrations.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.status = "succeeded";
          state.integrations = action.payload;
        }
      )
      .addCase(
        fetchIntegrations.rejected,
        (state, action: PayloadAction<{error: string} | undefined>) => {
          state.status = "failed";
          state.error = action.payload?.error ?? "Failed to fetch providers";
        }
      );
  },
});

export const {setFilter, clearFilters, loadFilter, searchIntegrations} =
  integrationsSlice.actions;

export default integrationsSlice.reducer;
