import {createSlice} from "@reduxjs/toolkit";
import type {PayloadAction} from "@reduxjs/toolkit";
import {MagicModal, MagicAttribute} from "interfaces/redux";

const initialState: MagicModal = {
  showModal: false,
  attributes: {
    key: "",
    product_id: 0,
  },
  showPriceDropdown: false,
  showMainPriceDropdown: false,
  allFiltersState: false,
};

export const magicSlice = createSlice({
  name: "lender",
  initialState,
  reducers: {
    setShowModal: (state, action: PayloadAction<boolean>) => {
      state.showModal = action.payload;
    },

    setShowPriceDropdown: (state, action: PayloadAction<boolean>) => {
      state.showPriceDropdown = action.payload;
    },

    setShowMainPriceDropdown: (state, action: PayloadAction<boolean>) => {
      state.showMainPriceDropdown = action.payload;
    },

    setAttributes: (state, action: PayloadAction<MagicAttribute>) => {
      state.attributes = structuredClone(action.payload);
      console.log(state.attributes);
    },
    setAllFiltersState: (state, action: PayloadAction<boolean>) => {
      state.allFiltersState = action.payload;
    },
  },
});

export const {
  setShowModal,
  setAttributes,
  setShowPriceDropdown,
  setShowMainPriceDropdown,
  setAllFiltersState,
} = magicSlice.actions;

export default magicSlice.reducer;
