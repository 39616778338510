import {createSlice, createAsyncThunk, PayloadAction} from "@reduxjs/toolkit";
import axios from "axios";
import Router from "next/router";

interface CountryKeywordState {
  country: string | null;
  keyword: string | null;
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
}

const initialState: CountryKeywordState = {
  country: "United Kingdom",
  keyword: "Loan Management Software",
  status: "loading",
  error: null,
};

export const fetchCountry = createAsyncThunk<
  any,
  void,
  {rejectValue: {error: string}}
>("countryKeyword/fetchCountry", async (_, thunkAPI) => {
  try {
    const response = await axios.get(
      `${process.env.NEXT_PUBLIC_STRAPI_BASE_URL}/lendsqr-seo-slugs?populate=*&pagination[page]=1&pagination[pageSize]=1000`,
      {
        headers: {
          Authorization: `Bearer ${process.env.NEXT_PUBLIC_STRAPI_API_KEY}`,
        },
      }
    );

    return response.data.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue({error: error.message});
  }
});

const keywordCountrySlice = createSlice({
  name: "countryKeyword",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCountry.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCountry.fulfilled, (state, action: PayloadAction<any>) => {
        state.status = "succeeded";

        // Get the segment after "countries" from the URL
        const path = window.location.pathname;
        const lastSegment = path.split("/").pop();

        const selectCountry = action.payload.find(
          (slug: {id: number; attributes: any}) =>
            slug.attributes.slug === lastSegment
        );

        // console.log(action.payload, selectCountry, lastSegment);

        if (action.payload && selectCountry !== undefined) {
          state.country = selectCountry.attributes.country;
          state.keyword = selectCountry.attributes.keyword;
        }
      })
      .addCase(
        fetchCountry.rejected,
        (state, action: PayloadAction<{error: string} | undefined>) => {
          state.status = "failed";
          state.error = action.payload?.error ?? "Failed to fetch countries";
        }
      );
  },
});

export default keywordCountrySlice.reducer;
