import {Action, ThunkAction, configureStore} from "@reduxjs/toolkit";

import lenderReducer from "./features/lenderSlice";
import magicReducer from "./features/magicSlice";
import filterReducer from "./features/filterSlice";
import lendersFilterReducer from "./features/lendersFilterSlice";
import offersReducer from "./features/offersSlice";
import allLendersReducer from "./features/allLendersSlice";
import tooltipReducer from "./features/tooltipSlice";
import allProvidersReducer from "./features/allProvidersSlice";
import providersFilterReducer from "./features/providersFilterSlice";
import integrationsReducer from "./features/integrationsSlice";
import keywordCountryReducer from "./features/keywordCountrySlice";

import {api} from "./services/api";

export const store = configureStore({
  reducer: {
    lender: lenderReducer,
    magic: magicReducer,
    [api.reducerPath]: api.reducer,
    filter: filterReducer,
    lendersFilter: lendersFilterReducer,
    offers: offersReducer,
    allLenders: allLendersReducer,
    tooltip: tooltipReducer,
    allProviders: allProvidersReducer,
    providersFilter: providersFilterReducer,
    integrations: integrationsReducer,
    keywordCountry: keywordCountryReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
